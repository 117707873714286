export const studio89photos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-117.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-7.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-8.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-9.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-10.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-13.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-63.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-64.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-65.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-66.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-67.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-68.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-69.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-70.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-71.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-72.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-73.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-74.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-76.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-77.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-79.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-81.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-83.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-84.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-85.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-86.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-89.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-92.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-93.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-94.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-95.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-97.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-98.jpg",
    width: 4,
    height: 3,
  },

  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Studio+89+Branding+Styleshoot/MiacoEvents-BrandingShoot-116.jpg",
    width: 4,
    height: 3,
  },
]
