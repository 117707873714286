import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { studio89photos } from "../../components/photos/studio-89-photos"
import "../../../styles/global-styles.scss"

const Studio89 = () => (
  <Layout>
    <Header />
    <SEO
      title="Studio 89 Photoshoot"
      description="Studio 89 offers a professional photography and videography studio space for rent in the Long Branch neighbourhood of Toronto’s west end. modern, versatile and elegant spaces provide the perfect backgrounds for family, wedding, fashion and promotional photography."
    />
    <h1 className="gallery-photos-heading">Studio 89 Photoshoot</h1>
    <p className="gallery-photos-text-body">
      A YESInspired style shoot at Studio 89. The studio space offers a
      professional photography and videography locations for rent in the Long
      Branch neighbourhood of Toronto’s west end. Modern, versatile and elegant
      spaces provide the perfect backgrounds for family, wedding, fashion and
      promotional photography.
    </p>
    <ImageGallery photos={studio89photos} />
  </Layout>
)

export default Studio89
